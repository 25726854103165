import React, {useState,useEffect} from "react";
import {Redirect, Route, Switch,} from "react-router-dom";
import {withRouter} from "react-router";
import Footer from './components/footer/footer';
import NotesContext from './context/notesContext';
import ReadMe from './screens/readme/ReadMe';

import IntermediatePage from "./components/InterMediatedPage/IntermediatePage";

// admin
import Maintenance from './admin/screen/Maintenance/Maintenance';
import ServicePlan from './admin/screen/ServicePlan/ServicePlan';
import FAQAdmin from './admin/screen/FAQ/FAQ';
import PreviewFAQ from './admin/screen/FAQ/PreviewFAQ';
import UserInformation from './admin/screen/UserInformation/UserInformation';
import Communication from './admin/screen/Communication/Communication';
import PageCreationTemplate from './admin/screen/CreationTemplate/PageCreationTemplate';
import TermsOfUseAdmin from './admin/screen/Termsofuse/TermsOfUseAdmin';
import PreviewTerms from './admin/screen/Termsofuse/PreviewTerms';
import PrivacyPolicyAdmin from './admin/screen/PrivacyPolicyAdmin/PrivacyPolicyAdmin';
import PolicyPreview from './admin/screen/PrivacyPolicyAdmin/PolicyPreview';
import Reporting from './admin/screen/Reporting/Reporting';
import SubscribePlan from './admin/screen/SubscribePlan/SubscribePlan';
import AccountPauseActivated from './admin/screen/Account/AccountPauseActivated';
import MaintenanceMain from "./components/MaintenanceLogin/MaintenanceMain";
import MaintenancePrivateRoute from "./PrivateRoute/maintenancePrivateRoute";
import DailyReporting from "./admin/screen/Daily Reporting/daily_Reporting";
import EmployeeCallSheet from "./admin/screen/EmployeeCallSheet";

import Howitworks from './screens/howitworks/howitworks';
import Privaypolicy from './screens/privacypolicy/privacypolicy';
import Termsofuse from './screens/termsofuse/termsofuse';
import axios from "axios";
import ChekinDetails from "./admin/screen/Checkin-details/chekin-details";

const App = () => {
    const tableData = [
        {
            "id": 1,
            "name": "Mark",
            "sharedwith": "admin",
            "time": "2/25/22 11:30 AM"
        },
    ];
    const token = localStorage.getItem('maintenance-token');
    const [notesData, setNotesData] = useState(tableData);
    axios.defaults.baseURL = process.env.REACT_APP_SERVER;
    axios.defaults.headers.Authorization = `Bearer ${token}`;

    return (
        <NotesContext.Provider value={{notesData, setNotesData}}>
            <main>
                <Switch>
                    <Route path="/" exact component={MaintenanceMain}/>
                    <Route path="/Intermediate-Page" exact component={IntermediatePage}/>

                    <Route path="/how" exact component={Howitworks}/>
                    <Route path="/privacy-policy" exact component={Privaypolicy}/>
                    <Route path="/terms-of-use" exact component={Termsofuse}/>

                    {/*<Route path="/maintenance-login" exact component={MaintenanceMain}/>*/}
                    <MaintenancePrivateRoute exact path="/maintenance" component={Maintenance}/>
                    <MaintenancePrivateRoute exact path="/service-plan" component={ServicePlan}/>
                    <MaintenancePrivateRoute exact path="/admin-faq" component={FAQAdmin}/>
                    <MaintenancePrivateRoute exact path="/faq-preview" component={PreviewFAQ}/>
                    <MaintenancePrivateRoute exact path="/user-information" component={UserInformation}/>
                    <MaintenancePrivateRoute exact path="/communication-details" component={Communication}/>
                    <MaintenancePrivateRoute exact path="/page-creation/:id?" component={PageCreationTemplate}/>
                    <MaintenancePrivateRoute exact path="/termsOfUseAdmin" component={TermsOfUseAdmin}/>
                    <MaintenancePrivateRoute exact path="/terms-preview" component={PreviewTerms}/>
                    <MaintenancePrivateRoute exact path="/subscribe-planAdmin" component={SubscribePlan}/>
                    <MaintenancePrivateRoute exact path="/privacy-policy-admin" component={PrivacyPolicyAdmin}/>
                    <MaintenancePrivateRoute exact path="/privacy-policy-preview" component={PolicyPreview}/>
                    <MaintenancePrivateRoute exact path="/reporting" component={Reporting}/>
                    <MaintenancePrivateRoute exact path="/DailyReporting" component={DailyReporting}/>
                    <MaintenancePrivateRoute exact path="/CheckinDetails/:recordID/:userId" component={ChekinDetails}/>
                    <MaintenancePrivateRoute exact path="/employee-call-sheet" component={EmployeeCallSheet}/>
                    <MaintenancePrivateRoute exact path="/account" component={AccountPauseActivated}/>
                    <MaintenancePrivateRoute path="/readme" exact component={ReadMe}/>
                    <Redirect to={'/'}/>
                </Switch>
                <Footer/>
            </main>
        </NotesContext.Provider>
    );
}

export default withRouter(App);
