import React from 'react';
import Header from '../../../admin/component/header/Header'
import { Card, Container,Col,Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css';
import MaintenanceScreen from '../../images/companyMaintenance Screen.jpg'
import MaintainenceLogout from '../../../components/logout/MaintainenceLogout';
import Back from '../../../assets/images/Back_white.png';

const   Maintenance = () => {

    return (
        <>
            <div className="maintenance-section common-font-section">
                <Header title="Maintenance Module" />
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        {/*<Link to="/maintenance-login" className="btn custom-link btn-primary">*/}
                        {/*    <img src={Back} alt="Back" className="w-5"/> Back*/}
                        {/*</Link>*/}
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <MaintainenceLogout/>
                    </Col>
                </Row>
                <div className="maintenance-content-card">
                    <Container>
                        <div className="maintenance-content-flex">
                            <Card>
                                <Link to="/employee-call-sheet">
                                    <Card.Body>
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title>Employee Call Sheet</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card >
                                <Link to="/user-information">
                                    <Card.Body >
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title >User's Maintenance</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card >
                                <Link to="/reporting">
                                    <Card.Body>
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title >Reporting</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card >
                                <Link to="/DailyReporting">
                                    <Card.Body>
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title >Daily Reporting</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card >
                                <Link to="/privacy-policy-admin">
                                    <Card.Body>
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title >Privacy Policy</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card >
                                <Link to="/termsOfUseAdmin">
                                    <Card.Body>
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title >Terms Of Use</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card >
                                <Link to="/readme">
                                    <Card.Body>
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title >Read Me</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card >
                                <Link to="/service-plan">
                                    <Card.Body>
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title >Service Plans</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card >
                                <Link to="/admin-faq">
                                    <Card.Body>
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title >Frequently Asked Questions</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card >
                                <Link to={`/subscribe-planAdmin`}>
                                    <Card.Body>
                                        <Card.Img src={MaintenanceScreen} />
                                        <Card.Title >Billing & Payments</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Maintenance
